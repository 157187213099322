import {
  theme as base,
  extendTheme,
  withDefaultVariant,
} from "@chakra-ui/react";

const theme = extendTheme(
  {
    config: {
      initialColorMode: "system", // Enables system-based theme
      useSystemColorMode: true, // Automatically switches based on system setting
    },
    fonts: {
      heading: `Expo Arabic, ${base.fonts?.heading}`,
      body: `Expo Arabic, ${base.fonts?.body}`,
    },
    components: {
      Input: {
        variants: {
          filled: {
            field: {
              _focus: {
                borderColor: "blue.500",
              },
            },
          },
        },
      },
      Button: {
        baseStyle: {
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },
  },
  withDefaultVariant({
    variant: "filled",
    components: ["Input", "Select", "Textarea"],
  })
);
export default theme;
